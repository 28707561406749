<template>
    <div class="professional-view">
        <section class="visual">
            <div class="row">
                <div class="col-12 m-col-6">
                    <h1>Maak van je<br />oude verf,<br />nieuwe verf</h1>
                </div>
                <div class="col-12 m-col-6 text-right">
                    <img src="/static/img/pppw.png" class="pppwlogo" alt="PPPW Logo">
                </div>
            </div>
        </section>

        <section class="gray">
            <div class="row">
                <div class="col-12 m-col-4 xl-col-3">
                    <h2>Ik ben een vak&shy;schilder</h2>
                </div>
                <div class="col-12 m-col-8 xl-col-9">
                    <p>Als professional kun jij met jouw ervaring goed inschatten hoeveel verf er nodig is voor een klus. Toch blijven er regelmatig verfrestanten over. Wat doe je daarmee? Veel laten het achter bij de klant of opdrachtgever, gebruiken het voor een volgende klus, doneren het of laten het ophalen door een afvalverwerker van chemisch afval.</p>
                </div>
            </div>
        </section>

        <section class="white">
            <div class="row">
                <div class="col-12 m-col-6">
                    <img src="/static/img/paint-bucket.png" class="slide slide1" alt="">
                </div>
                <div class="col-12 l-col-6 vcenter">
                    <div>
                        <h2>De beste keuze voor het milieu</h2>
                        <p>Waar je ook voor kiest, het is belangrijk om te weten dat deze verfrestanten het milieu kunnen schaden wanneer we er niet op de juiste manier mee omgaan.</p>
                        <p>Verfrestanten laten ophalen door een afvalverwerker is een slimme en veilige keuze. Kies je voor deze optie, dan weet je zeker dat verfrestanten niet in huisafval of het riool terechtkomen. Soms kan verf zelfs worden gerecycled.</p>
                        <p>Sikkens produceert samen met InterCheM de Sikkens Alpha Recycle Mat &mdash; een muurverf welke uit 35% gerecyclede verf bestaat.</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="video">
            <div class="row">
                <div class="col-12">
                    <h2>Benieuwd hoe gerecyclede verf wordt gemaakt? In deze video leggen we het uit:</h2>
                </div>
                <div class="col-12">
                    <div class="video-holder">
                        <video src="/static/video/interchem.mp4" controls></video>
                    </div>
                </div>
            </div>
        </section>

        <section class="interchem">
            <div class="row">
                <div class="col-12">
                    <h2>Interchem's zes stappen</h2>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <h4 class="main">InterCheM is gespecialiseerd in het ophalen en verwerken van verf. Wanneer je je verfrestanten laat ophalen door InterCheM, weet je zeker dat jouw oude verf op de juiste en meest milieuvriendelijke manier wordt verwerkt. De afvoer van verfafval via InterCheM gebeurt via zes stappen:</h4>
                </div>
            </div>

            <div class="row">
                <div class="col-12 m-col-6">
                    <h3>1. Kennismaking</h3>
                    <p>InterCheM komt eerst persoonlijk bij je langs om kennis te maken met jou, je bedrijf en je productieprocessen. Ze vertellen dan meteen meer over hun eigen werkwijze.</p>
                </div>
                <div class="col-12 m-col-6">
                    <h3>2. Inventarisatie verfafval</h3>
                    <p>InterChem neemt zijn rol als inzamelaar serieus en gaat proactief met je aan de slag om tot een juiste inventarisatie en classificatie van de verfrestanten te komen.</p>
                </div>
            </div>

            <div class="row">
                <div class="col-12 m-col-6">
                    <h3>3. Plaatsen van de order</h3>
                    <p>Na de classificatie ontvang je van InterCheM een prijsopgave en sturen ze je de aanlevervoorwaarden, zodat het verfafval op juiste wijze getransporteerd kan worden. Zodra jullie allebei akkoord zijn, kan de order worden geplaatst.</p>
                </div>
                <div class="col-12 m-col-6">
                    <h3>4. Delen van de planning</h3>
                    <p>Nadat InterCheM de geplaatste order heeft gecontroleerd, ontvang je een bevestiging ter controle. Ook stuurt InterCheM direct een planning en ophaaldatum mee.</p>
                </div>
            </div>

            <div class="row">
                <div class="col-12 m-col-6">
                    <h3>5. Ophalen van de verfrestanten</h3>
                    <p>De chauffeur controleert de verfrestanten op basis van de opgemaakte transportdocumenten. Als alles akkoord is, neemt hij het mee.</p>
                </div>
                <div class="col-12 m-col-6">
                    <h3>6. Verwerking van de verfrestanten</h3>
                    <p>Eenmaal aangekomen bij InterCheM worden de verfresten verwerkt. De definitieve factuur wordt opgemaakt en digitaal naar je verstuurd, zodat ook jouw eigen afvalbalans en registratie klopt.</p>
                </div>
            </div>

            <div class="more-info">
                <div class="row">
                    <div class="col-12">
                        <h4>Interesse? Kijk op de site van InterCheM voor meer<br />informatie of om je aan te melden:</h4>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <a href="https://interchem.nl/verfrecycling/" class="button white" target="_blank">Meer info</a>
                    </div>
                </div>
            </div>
        </section>

        <section class="quotes">
            <h2>Duurzame tips voor de vakschilder</h2>
            <v-slick-carousel v-bind="settings" >
                <h3>“Sluit na gebruik de bus goed en houd hem even op de kop. Er loopt zo wat verf in de rand tussen deksel en bus, waardoor de verf luchtdicht is afgesloten en niet voortijdig gaat indrogen.”</h3>
                <h3>“Spoel je gebruikte rollers en kwasten niet uit onder de kraan, maar hergebruik ze of gooi ze bij het restafval. Dit is minder milieubelastend dan verf die in het riool terechtkomt.”</h3>
                <h3>“Kies voor muurverf met een hoge dekking, zoals Sikkens Alphatex SF, zo schilder je meer meters met minder verf.”</h3>
            </v-slick-carousel>
        </section>

        <section class="gemiddeldevakschilder">
            <div class="row">
                <div class="col-12 l-col-5 vcenter">
                    <div>
                        <h2>Hoe duurzaam werkt de gemiddelde vakschilder?</h2>
                        <p>Samen met AkzoNobel vroegen we 181 vakschilders naar hun werkwijze en visie op gebied van duurzaamheid. We hebben de uitkomsten op een rijtje gezet:</p>
                        <v-slick-carousel v-bind="settings" >
                            <div>
                                <p>“Gemiddeld houdt een derde van de respondenten bijna 100 liter verf over per maand. 61% laat verf achter bij de klant/opdrachtgever of gebruikt het voor een volgende klus, slechts 24% laat verfrestanten ophalen door een afvalverwerker.”</p>
                            </div>

                            <div>
                                <p>“61% verwacht dat gerecyclede verf dezelfde kwaliteit biedt als normale verf en 28% is bereid hier meer voor te betalen in het geval de kwaliteit identiek is aan “normale” verf.”</p>
                            </div>

                            <div>
                                <p>“88% van de respondenten vindt duurzaamheid belangrijk. De voornaamste reden is het milieu (84%), gevolgd door persoonlijke gezondheid (59%) en daarna komt de vraag vanuit de klant of opdrachtgever (18%).”</p>
                            </div>

                            <div>
                                <p>“81% van de respondenten staat open voor een watergedragen buitenlak, mits het eindresultaat hetzelfde is als oplosmiddelhoudend.”</p>
                            </div>

                            <div>
                                <p>“De respondenten vinden voornamelijk dat duurzame verf een gerecyclede verpakking moet hebben (56%), of een gerecyclede inhoud (49%). 44% vindt een lagere CO2 belangrijk en 43% vindt het ontbreken van oplosmiddelen belangrijk.”</p>
                            </div>

                        </v-slick-carousel>
                    </div>
                </div>

                <div class="col-12 m-col-6 l-col-offset-1">
                    <img src="/static/img/vakschilder_low.jpg" class="slide slide2" alt="">
                </div>
            </div>
        </section>
    </div>
</template>

<style lang="scss">
    @import "base/settings";

    .professional-view {
        padding: 100px 10px 10px 10px;
        @media (min-width: $medium ) {
            padding: 100px 30px 30px 30px;
        }
        h1 {
            margin-bottom: 0;
            line-height: 1.15;
        }

        // Visual
        .visual {
            margin: -100px -10px 0 -10px;
            @media (min-width: $medium ) {
                margin: -100px -30px 0 -30px;
            }
            padding: 100px 10px 175px 10px;
            @media (min-width: $small) {
                padding: 100px 30px 175px 30px;
            }
            h1 {
                text-transform: uppercase;
            }

            .text-right {
                text-align: right;
            }
            background: white url(/static/img/header-vakschilder.jpg) no-repeat top center;
            background-size: cover;
            min-height: 60vh;
        }

        .gray {
            background-color: $gray;
            padding: 50px;
            color: #fff;

            margin: 0 -10px 0 -10px;
            @media (min-width: $medium ) {
                margin: 0 -30px 0 -30px;
            }

            h2 {
                text-transform: uppercase;
            }

            p {
                font-family: 'HelveticaNeue';
                border-left: 3px solid #fff;
                padding-left: 50px;
                @media (min-width: $large)
                {
                    font-size: 1.6rem;
                }
            }
        }

        .white {

            h2 {
                text-transform: uppercase;
            }

            p {
                font-family: 'HelveticaNeue';
            }

            @media (min-width: $small) {
                img {
                    padding-right: 50px;
                }
            }
        }

        .video {
            position: relative;
            padding-top: 0;

            h2 {
                margin: 0;
                text-align: center;
                font-size: 3.5em;
                @media (min-width: $small)
                {
                    font-size: 4em;
                }
                @media (min-width: $medium)
                {
                    font-size: 4.5em;
                }
            }

            &::before {
                content: '';
                background: transparent url(/static/img/dots.svg) no-repeat top center;
                background-size: contain;
                position: absolute;
                top: -75%;
                left: -20%;
                width: 50vw;
                transform: rotate(180deg);
                height: 100%;
                z-index: -1;
            }

            .video-holder {
                text-align: center;
                padding: 2.5em 0;
            }
        }

        section {
            padding: 15px;
            @media (min-width: $small) {
                padding: 80px;
            }
            @media (min-width: $medium) {
                padding: 160px;
            }
        }

        .interchem {
            background-color: $blue;
            color: white;
            margin: 0 -10px 0 -10px;
            @media (min-width: $medium ) {
                margin: 0 -30px 0 -30px;
                padding-top: 80px;
            }

            h2 {
                text-transform: uppercase;
                text-align: center;
            }

            h3 {
                margin: 0;
                padding-left: 50px;
                text-transform: uppercase;
                color: $green;
            }
            @media (min-width: $medium)
            {
                h3 {
                    min-height: auto;
                }
            }

            h4 {
                text-align: center;
                padding: 0 20%;
                font-family: 'HelveticaNeue';
                margin-top: 0;
            }
            h4.main {
                margin-bottom: 2em;
                @media (min-width: $medium ) {
                    margin-bottom: 4em;
                }
            }

            p {
                font-family: 'HelveticaNeue';
                margin: 0.5em 0 2em;
                padding: 0 50px;
            }

            .more-info {
                text-align: center;
            }
        }

        .quotes {
            background: $lightgray;
            position: relative;
            margin: 0 -10px 0 -10px;
            @media (min-width: $medium ) {
                margin: 0 -30px 0 -30px;
            }

            h2 {
                color: $blue;
                text-align: center;
                text-transform: uppercase;
            }

            h3 {
                color: $blue;
                text-align: center;
                margin: 0;

                font-size: 1.5rem;
                @media (min-width: $small) {
                    font-size: 2rem;
                }
                @media (min-width: $medium) {
                    font-size: 2.5rem;
                }
            }
            .slick-dots {
                bottom: -50px;
            }
            .slick-dots li button:before,
            .slick-dots li.slick-active button:before {
                color: $blue;
            }

            &:after {
                content: '';
                background: transparent url(/static/img/dots.svg) no-repeat top center;
                background-size: contain;
                position: absolute;
                bottom: 0;
                right: 0;
                width: 50vw;
                transform: translateX( 25vw ) translateY( 50vh );
                height: 100%;
            }
        }

        .gemiddeldevakschilder {
            h2 {
                text-transform: uppercase;
            }

            p {
                font-family: 'HelveticaNeue';
                padding-right: 175px;
            }

            .slick-dots {
                text-align: left;
            }

            .slick-dots li button:before,
            .slick-dots li.slick-active button:before {
                color: $blue;
            }
        }

        .slide1, .slide2 {
            opacity: 0;
        }
        .slide1.animate {
            animation: slideImageLeftToRight 1s ease-in-out forwards;
        }
        .slide2.animate {
            animation: slideImageRightToLeft 1s ease-in-out forwards;
        }
    }
</style>

<script>
    import VueSlickCarousel from 'vue-slick-carousel';

    export default {
        name: 'Professional',
        components: {
            'v-slick-carousel': VueSlickCarousel
        },

        data()
        {
            return {
                settings: {
                    infinite: true,
                    slidesToShow: 1,
                    dots: true,
                    arrows: false,
                    autoplay: true,
                    autoplaySpeed: 8000,
                    draggable: true,
                    swipe: true,
                    touchMove: true
                }
            };
        },

        mounted()
        {
            this.observeDocument();
        },

        methods: {
            observeDocument()
            {
                const options = {
                    root: null,
                    rootMargin: '0px',
                    threshold: 0.5
                };

                this.observer = new IntersectionObserver( entries =>
                {
                    entries.forEach( entry =>
                    {
                        const image = entry.target;

                        if( entry.isIntersecting )
                        {
                            image.classList.add( 'animate' );
                        }
                    });
                }, options );

                const slides = document.querySelectorAll( '.slide' );
                slides.forEach( ( el ) =>
                {
                    this.observer.observe( el );
                });
            },

            beforeDestroy()
            {
                this.observer.disconnect();
            }
        }
    };
</script>
